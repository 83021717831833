const { registerBlockType } = wp.blocks;

var wpep_block_container = {
	"text-align": 'center'
};

var divStyle = {
	// "margin-bottom": "40px",
	"margin-bottom": "20px",
};

var wpep_logo = {

	// filter: "grayscale(100%)",
	width: "100px"
}


registerBlockType(
	'mycred/submissions',
	{

		title: 'myCred Submissions',
		description: 'Block to add submissions shortcode to the page',
		icon: 'dashicons-before dashicons-mycred',
		category: 'mycred',
		attributes: {
			id: {
				type: 'string',

			}
		},
		edit( props ) {
			

			var badge   = submission_forms.badge;
			var rank 	= submission_forms.rank;
			var field 	= submission_forms.check_fields
			var options = [];

			options.push( <option value ="" > Select < / option > )
			options.push( <optgroup label="Badges"></optgroup > )
			for (var key in badge) {
				
				var badge_field = field[key].badge;
				var rank_field = field[key].rank;

				if(badge_field == 'on' ){
					if (badge.hasOwnProperty( key )) {
						var form_id    = badge[key].ID;
						var form_title = badge[key].title;
						if (props.attributes.id == form_id) {

							options.push( < option value = {form_id} selected > {form_title} < / option > )

						} else {

							options.push( < option value = {form_id} > {form_title} < / option > )
						}
					}
				}
			}

			options.push( <optgroup label="Ranks"></optgroup > )
			for (var key in rank) {
			
				var badge_field = field[key].rank;
				var rank_field = field[key].rank;
				if(rank_field == 'on'){
					if (rank.hasOwnProperty( key )) {
						var form_id    = rank[key].ID;
						var form_title = rank[key].title;
						if (props.attributes.id == form_id) {

							options.push( < option value = {form_id} selected > {form_title} < / option > )

						} else {

							options.push( < option value = {form_id} > {form_title} < / option > )
						}
					}

				}
			}

			var id = props.attributes.id;

			function wpep_shortcode_change(e) {

				var form_id = e.target.value;
				props.setAttributes( {id: form_id} );
			}

			return (
			<div style={wpep_block_container}> <div style={divStyle}> <img style={wpep_logo} src={'https://sp-ao.shortpixel.ai/client/q_lossless,ret_img/https://ultimatemember.com/wp-content/uploads/bb-plugin/cache/mycred-circle.png'} /> </div> <div> <select onChange={wpep_shortcode_change}> {options} </select> </div> </div>
		);

		},
		save( props ) {
			return null;
		}

	}
);
